.expanded-rows {
  padding: 20px 60px !important;
}

.timeline {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
  padding: 0 30px;
}

.timeline .timeline-item {
  position: relative;
}

.timeline .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline .timeline-item:after {
  background-color: hsl(0, 0%, 90%);
  left: -36px;
  border-radius: 50%;
  height: 11px;
  width: 11px;
  content: "";
}

h5.status {
  font-size: 14.4px !important;
  font-weight: bold !important;
}
