//color
$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$hover-grey: #d9d9d9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$hover-blue: #174cb5;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$red: #fc351c;
$hover-red: #e21d03;
$pink: #eb30a2;

//profile form
@media (max-width: 815px) {
  .form-profile {
    .col-sm-12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

//tab
.nav-tabs.tab-custom {
  button.nav-link {
    font-size: 0.9rem;
    color: $light-black;
  }
}

.form-profile {
  input:read-only,
  textarea:read-only {
    background-color: $light-grey;
    color: $black;
  }
  .btn-blue {
    min-width: unset !important;
    color: $white !important;
  }
}

.card-header {
  background-color: $light-grey;
}

.btn-eye {
  background-color: #fff;
  color: #1c1b20;
  border-color: #d1d3e2;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
  border-left: unset;
  z-index: 1;
  padding-top: unset;
  padding-bottom: unset;
}
.btn-eye:active,
.btn-eye:hover,
.btn-eye:focus {
  color: #1c1b20 !important;
  background-color: #fff !important;
  border-color: #d1d3e2 !important;
}

//change profile pic
.form-profile-pic {
  .btn-blue {
    min-width: unset !important;
    color: $white !important;
  }
  .btn-grey {
    min-width: unset !important;
  }
}
