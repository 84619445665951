//color
$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$hover-grey: #d9d9d9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$hover-blue: #174cb5;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$red: #fc351c;
$hover-red: #e21d03;
$pink: #eb30a2;

//employee form
@media (max-width: 815px) {
  .form-employee {
    .col-sm-12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

//employee form
@media (max-width: 515px) {
  .form .btn-blue,
  .form .btn-grey {
    width: 100%;
    margin-right: 0;
  }
  .form .btn-grey {
    margin-bottom: 0.5rem;
  }
}

//tab
.nav-tabs.tab-custom {
  button.nav-link {
    font-size: 0.9rem;
    color: $light-black;
  }
}

//input file
.input-file-custom {
  font-size: 0.9rem;
  padding-top: 0.19rem;
}

//example file
.example-file {
  .file a {
    color: $green;
  }
}

.select-group {
  flex: 1 1 auto;
}

.btn-grey.btn-sync {
  min-width: unset !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
