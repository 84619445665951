//color
$grey: #f0f0f0;
$dark-blue: #1c5ddc;
$white: #fff;
$light-blue: #e8effc;
$hover-blue: #174cb5;
$light-grey: #f9f9f9;
$red: #fc351c;
$hover-red: #e21d03;

/* Full screen container */
.full-screen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Center the chat container */
.chat-container {
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px;

  .chat-message {
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .message-time {
    font-size: 0.9rem;
  }

  .sender-message {
    background-color: $light-blue;
    align-self: flex-end;
  }

  .receiver-message {
    background-color: $grey;
    align-self: flex-start;
  }

  .message-text {
    color: #333;
  }
}

/* User info and input section styles */
.user-info {
  padding: 10px;
  background-color: $dark-blue;
  color: $white;
  font-size: 0.9rem;
  .no-tlp,
  .nama {
    font-weight: bold;
    letter-spacing: 1px;
    word-spacing: 2px;
  }
  .nama {
    margin-top: 3px;
  }
}

.div-input-chat {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f0f0f0;

  input[type="text"],
  input[type="file"] {
    flex-grow: 1;
    border: none;
    border-radius: 5px 0 0 5px;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
    height: 40px;
  }

  input[type="text"] {
    padding: 8px 15px;
  }

  input[type="file"] {
    padding: 5px 15px;
  }

  .btn-primary.btn-send,
  .btn-primary.btn-end {
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 40px;
  }
  .col-btn-send {
    padding-right: 5px !important;
  }
  .col-btn-end {
    padding-left: 5px !important;
  }
  .btn-send:hover {
    background-color: $hover-blue !important;
  }
  .btn-primary.btn-send {
    background-color: $dark-blue !important;
  }
  .btn-primary.btn-end {
    background-color: $red !important;
  }
  .btn-end:hover {
    background-color: $hover-red !important;
  }
  .import-file {
    background-color: #fff;
    border-color: #ccc;
    height: 40px;
    width: 55px;
    border-radius: 0 5px 5px 0;
    background-color: $light-grey;
    border-left: none;
  }
}

.div-input-chat .col-8 {
  padding-right: 5px;
}

.div-input-chat .col-2 {
  padding: 0 5px;
}

@media (max-width: 750px) {
  .div-input-chat .button-name {
    display: none;
  }
}
