$dark-blue: #1c5ddc;
$light-blue: #00aaff;

 .add-deals {
    background-color: $dark-blue;
    position: relative;
    z-index: auto;
    bottom: auto;
    right: auto;
    &:hover{
        background-color: #00aaff;
    }
  }