$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$red: #fc351c;
$pink: #eb30a2;

// sidebar
.sidebar {
  width: fit-content;
  overflow-y: scroll;
  max-height: 100vh;
  a {
    color: $light-black;
  }
  &.sidebar-white {
    background-color: $light-grey !important;
  }
  .nav-item {
    padding: 0 1rem;
  }
  .nav-item .nav-link {
    padding: 0.6rem 1rem;
    width: 100%;
  }
  .nav-item.active .nav-link {
    background-color: $light-black;
    color: $white;
    border-radius: 10px;
    font-weight: normal;
  }
  .nav-item:hover {
    text-decoration: none;
  }
  .collapse-inner {
    padding: 0 !important;
  }
  .collapse-item {
    margin: 0 !important;
    white-space: break-spaces !important;
  }
  .collapse-item:hover {
    color: $light-black;
    background-color: $grey !important;
  }
  .collapse-item.active {
    color: $white !important;
    background-color: $light-black !important;
    font-weight: normal !important;
  }
}
// z index 1
.z-1 {
  z-index: 1;
}

@media (max-width: 920px) {
  .nav-item {
    padding: 0 0.5rem !important;
  }
}

@media (max-width: 767px) {
  .sidebar {
    .nav-item .nav-link {
      padding: 0.5rem 0.7rem !important;
      width: 100% !important;
    }
    .nav-item .nav-link span {
      font-size: 0.75rem !important;
    }
    .nav-item {
      padding: 0 0.5rem !important;
    }
    &.toggled2 {
      min-width: 0 !important;
    }
    .nav-link .mr-2 {
      margin-right: 0 !important;
    }
  }
}

@media (min-width: 768px) {
  .sidebar {
    width: 25rem !important;
    height: 100vh;
    overflow-y: scroll;
    .nav-item .nav-link {
      padding: 0.6rem 1rem;
      width: 100%;
    }
    .nav-item .collapse,
    .nav-item .collapsing {
      margin: 0;
    }
    &.toggled .nav-item {
      padding: 0 0.5rem;
    }
    &.toggled .nav-item .nav-link {
      padding: 0.5rem 0.7rem;
      width: 100%;
    }
    &.toggled {
      .nav-item .nav-link span {
        font-size: 0.75rem;
      }
    }
  }
  .sidebar.toggled {
    width: fit-content !important;
    height: 100vh;
    overflow-y: scroll;
    .nav-link .mr-2 {
      margin-right: 0 !important;
    }
    .nav-item .collapse {
      position: fixed;
      left: 11rem;
      z-index: 1;
      top: calc(7.5rem + 1.5rem / 2);
    }
  }
}

@media (max-width: 400px) {
  #wrapper {
    min-height: 100vh;
  }
  .sidebar {
    position: fixed;
    overflow-y: scroll;
    height: 100vh;
    max-width: 7rem;
    z-index: 2;
  }
  .row-reverse {
    flex-direction: row-reverse;
  }
  .sidebar .nav-item .collapse {
    position: fixed;
    left: calc(6.5rem + 1.5rem / 2);
    z-index: 1;
    top: 35%;
  }
}

// custom color
.custom-black-color {
  color: $light-black !important;
}
// navbar
.navbar {
  box-shadow: 0 4px 2px -2px $grey;
  #userDropdown span {
    font-size: 0.85rem;
  }
  .dropdown-menu {
    margin-top: 0.7rem;
    padding: 0;
    .dropdown-item {
      padding: 0.5rem 1rem;
      color: $light-black;
    }
    .dropdown-item:hover {
      background-color: $light-grey;
    }
    .dropdown-item:focus {
      color: $light-black !important;
      background-color: $light-grey;
    }
  }
  #sidebarToggleTop .fa.fa-bars {
    color: $light-black;
  }
  .size-icon {
    font-size: 1.4rem;
  }
  .icon-bell {
    color: $yellow;
  }
  .icon-wa {
    color: $green;
  }
  .icon-chat {
    color: #a6a6a6;
  }
  .badge-danger {
    background-color: $red;
  }
  .photo-profile {
    object-fit: cover;
  }
  .div-scroll-notification {
    max-height: 200px;
    overflow-y: auto;
  }
}
//notif
.popup-notif {
  font-size: 0.85rem !important;
  .dropdown-header {
    background-color: $dark-blue !important;
    border-color: $dark-blue !important;
    font-size: 0.85rem !important;
  }
  .unread {
    background-color: $grey;
    font-weight: bold;
  }
  .unread:hover {
    background-color: $grey !important;
  }
  .dropdown-item {
    padding: 0.25rem 1.5rem !important;
  }
}

// content
#content {
  background-color: $white;
  color: $light-black;
  .page-title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}
// footer
#main-footer {
  background-color: $white;
  color: $light-black;
  padding: 1rem 0;
}

// profile.js
table {
  color: $light-black !important;
}

.scroll-to-top {
  background-color: $light-black;
  z-index: 2;
  bottom: 3rem;
  right: 1.75rem;
}
.scroll-to-top:hover {
  background-color: $black;
}

//navbar
.navbar-mb {
  margin-bottom: 1.5rem;
  padding-left: 24px;
  padding-right: 24px;
}
.navbar-content {
  .dropdown-toggle::after {
    display: none !important;
  }
  .nav-link {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
  .select2 * {
    font-size: 0.9rem !important;
  }
}
#divReviewsDropdown .dropdown-header {
  background-color: $yellow !important;
  border-color: $yellow !important;
  font-weight: bold;
}
#divMessagesDropdown .dropdown-header {
  background-color: #a6a6a6 !important;
  border-color: #a6a6a6 !important;
  font-weight: bold;
}
#divChatRequestDropdown .dropdown-header {
  background-color: $green !important;
  border-color: $green !important;
  font-weight: bold;
}
@media (max-width: 767px) {
  .topbar {
    height: unset;
  }
  .navbar.navbar-content {
    align-items: unset !important;
    .text-right .ml-auto {
      margin-left: 0 !important;
    }
    .select2 {
      margin-bottom: 1rem;
    }
  }
}
@media (max-width: 400px) {
  .set-align-right {
    text-align: right;
  }
  #sidebarToggleTop.mr-3 {
    margin-right: 0 !important;
  }
  .set-display-block.d-flex {
    display: block !important;
  }
  .add-m3 {
    margin-bottom: 3px;
    margin-top: 3px;
  }
}
@media (max-width: 359px) {
  .navbar-content {
    .nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}
.overflow-message {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
