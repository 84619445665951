.container-fluid.dashboard * {
  font-size: 0.9rem;
}

.chart {
  width: 100%;
  min-height: 470px;
  padding: 1.5rem;
}

.pie-chart {
  width: 55%;
}

.doughnut-chart {
  width: 60%;
}

@media (min-width: 1300px) {
  .chart {
    min-height: 550px;
  }
  .pie-chart {
    width: 50%;
  }
  .doughnut-chart {
    width: 55%;
  }
}

@media (max-width: 1000px) {
  .chart {
    min-height: 500px;
  }
  .pie-chart {
    width: 65%;
  }
  .doughnut-chart {
    width: 75%;
  }
  .scroll-chart,
  .scroll-big-chart {
    overflow-x: scroll;
  }
  .responsive-chart {
    width: 450px !important;
    display: block !important;
    align-self: unset !important;
  }
  .responsive-big-chart {
    width: 700px !important;
    display: block !important;
    align-self: unset !important;
  }
}

@media (max-width: 900px) {
  .pie-chart {
    width: 80%;
  }
  .doughnut-chart {
    width: 90%;
  }
}

@media (max-width: 800px) {
  .pie-chart {
    width: 100%;
  }
  .doughnut-chart {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .card-chart.card .card-header[data-toggle="collapse"] {
    padding-left: 1rem !important;
  }
  .chart {
    padding: 1rem;
    min-height: unset;
  }
  .pie-chart {
    width: 50%;
  }
  .doughnut-chart {
    width: 50%;
  }
  .scroll-chart {
    overflow-x: unset;
  }
  .responsive-chart {
    width: 100% !important;
    display: flex !important;
    align-self: center !important;
  }
}

@media (max-width: 570px) {
  .doughnut-chart {
    width: 55%;
  }
}

@media (max-width: 412px) {
  .doughnut-chart {
    width: 80%;
  }
}

@media (max-width: 475px) {
  .scroll-chart {
    overflow-x: scroll;
  }
  .responsive-chart {
    width: 400px !important;
    display: block !important;
    align-self: unset !important;
  }
}

@media (max-width: 400px) {
  .pie-chart {
    width: 65%;
  }
}

@media (max-width: 316px) {
  .doughnut-chart {
    width: 100%;
  }
}

@media (max-width: 300px) {
  .scroll-pie-chart {
    overflow-x: scroll;
  }
  .responsive-pie-chart {
    width: 220px !important;
    display: block !important;
    align-self: unset !important;
  }
  .pie-chart {
    width: 100%;
  }
}

.collapse-border {
  border-top: 1px solid #e3e6f0;
}

.card-chart .card-header {
  color: #1c1b20;
}

.card-chart .card-header[data-toggle="collapse"]::after {
  top: 50%;
  transform: translateY(-50%);
}
