body {
  background-color: #f9f9f9;
}
@media (max-width: 767px) {
  .container-login * {
    font-size: 0.9rem !important;
  }
  .box-login .title {
    font-size: 1.2rem !important;
  }
}
@media (min-width: 768px) {
  .container-login * {
    font-size: 1rem !important;
  }
  .box-login .title {
    font-size: 1.3rem !important;
  }
}
@media (max-width: 406px) {
  .container.container-login {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.card-login {
  margin-top: 6rem;
}
.box-login {
  padding: 2.5rem 2rem;
  color: #1c1b20;
  .form-label {
    margin-bottom: 0.2rem;
  }
  .form-control {
    color: #1c1b20;
  }
  .form-control#formPassword {
    border-right: 0;
  }
  .btn-eye {
    background-color: #fff;
    color: #1c1b20;
    border-color: #d1d3e2;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left: unset;
    z-index: 1;
  }
  .btn-eye:active {
    color: #1c1b20 !important;
    background-color: #fff !important;
    border-color: #d1d3e2 !important;
  }
  .form-login {
    margin-top: 2rem;
  }
  .btn-login {
    background-color: #1c1b20;
    border-color: #1c1b20;
    margin-top: 0.5rem;
  }
  .btn-login:hover {
    background-color: #000;
  }
  .btn-login:active {
    background-color: #000 !important;
  }
}
