//color
$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$hover-grey: #d9d9d9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$hover-blue: #174cb5;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$hover-green: #169c53;
$red: #fc351c;
$hover-red: #e21d03;
$pink: #eb30a2;

$transition-collapse: height 0.35s ease;

.collapse {
  &:not(.show) {
    display: none;
  }
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: $transition-collapse;
}

.accordion {
  .card {
    overflow: unset;
    .card-header {
      background-color: #f9f9f9;

      button {
        color: black;
        text-decoration: none;
      }
      &:hover {
        background-color: $hover-grey;
        button {
          color: #1c1b20;
        }
      }
      button:focus {
        box-shadow: unset;
      }
    }
  }
}

.dropdown-detail {
  z-index: unset;
}

.btn-aksi {
  font-size: 0.9rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

  &.btn-delete {
    background-color: $red;
    color: $white !important;
    border-color: $red;

    &:hover {
      background-color: $hover-red;
    }
  }
  &.btn-edit {
    background-color: $dark-blue;
    color: $white !important;
    border-color: $dark-blue;

    &:hover {
      background-color: $hover-blue;
    }
  }
  &.btn-edit2 {
    background-color: $light-blue;
    color: $white !important;
    border-color: $light-blue;

    &:hover {
      background-color: $hover-blue;
    }
  }
  &.btn-reset {
    background-color: $green;
    color: $white;
    border-color: $green;
    &:hover {
      background-color: $hover-green;
    }
  }
  &.btn-reject {
    background-color: $red;
    color: $white;
    border-color: $red;
  }

  &.btn-lg {
    padding: 0.8rem;
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  60% {
    opacity: 1;
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.custom-modal-faq {
  display: none;
  background: rgba(0, 0, 0, 0.4);
  transition: background-color 0.1s;
  .modal-content {
    background-color: white;
    border: 0;
    border-radius: 5px;
    padding: 20px;
  }
  .modal-header {
    border: 0;
  }
  .modal-title {
    padding: 0.8em 1em 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
  }
  .modal-footer {
    border: 0;
    padding: 0 2em 0.8em 2em;
    button {
      margin: 0.3125em;
      padding: 0.625em 1.1em;
      transition: box-shadow 0.1s;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0);
      font-size: 1rem;
      border-radius: 0.25em;
    }
  }
  .modal-body {
    padding: 1em 2em;
    input[type="text"] {
      height: 2.625em;
      padding: 0 0.75em;
      box-sizing: border-box;
      transition: border-color 0.1s, box-shadow 0.1s;
      border: 1px solid #d9d9d9;
      border-radius: 0.1875em;
      background: rgba(0, 0, 0, 0);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06),
        0 0 0 3px rgba(0, 0, 0, 0);
      font-size: 1.125em;
      &:focus {
        border: 1px solid #b4dbed;
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06),
          0 0 0 3px rgba(100, 150, 200, 0.5);
      }
    }
    textarea {
      padding: 0.75em;
      box-sizing: border-box;
      transition: border-color 0.1s, box-shadow 0.1s;
      border: 1px solid #d9d9d9;
      border-radius: 0.1875em;
      background: rgba(0, 0, 0, 0);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06),
        0 0 0 3px rgba(0, 0, 0, 0);
      font-size: 1.125em;
      &:focus {
        border: 1px solid #b4dbed;
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06),
          0 0 0 3px rgba(100, 150, 200, 0.5);
      }
    }
  }
  &.show {
    display: block;
    transition: opacity 0.3s, transform 0.3s;
    .modal-dialog {
      animation: bounceIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.54);
    }
  }
}

.btn-collapse {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

//faq recursive
@media (max-width: 900px) {
  #faqlist {
    .set-900-col12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      &.div-btn {
        margin-top: 0.5rem;
        text-align: left !important;
      }
    }
  }
}

@media (max-width: 850px) {
  #faqlist {
    .set-850-col12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      &.div-btn {
        margin-top: 0.5rem;
        text-align: left !important;
      }
    }
  }
}

@media (max-width: 768px) {
  //parent faq
  .parent-faq-row {
    .div-btn {
      text-align: left !important;
      margin-top: 0.5rem;
      .btn {
        min-width: 100px;
      }
    }
  }
}

.notes-red {
  color: $red;
  font-size: 14px;
}

// @media (max-width: 490px) {
//   #faqlist {
//     .card-body,
//     .card-header {
//       padding-left: 0;
//       padding-right: 0;
//     }
//   }
// }
