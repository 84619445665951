//color
$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$hover-grey: #d9d9d9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$hover-blue: #174cb5;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$hover-green: #169c53;
$red: #fc351c;
$hover-red: #e21d03;
$pink: #eb30a2;

//page title
.page-title {
  font-size: 1.1rem !important;
  font-weight: bold;
  text-transform: uppercase;
}

//sub page navigation
.breadcrumb-custom {
  background-color: $grey !important;
  font-size: 0.9rem;
  .breadcrumb-item.active {
    color: $light-black;
  }
  a {
    color: $dark-blue;
  }
}

//button add
.btn-blue {
  background-color: $dark-blue;
  min-width: 9rem;
  border-color: $dark-blue;
  font-size: 0.9rem;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  background-color: $hover-blue !important;
  border-color: $hover-blue !important;
}

//button back
.btn-grey {
  background-color: $grey;
  min-width: 9rem;
  border-color: $grey;
  font-size: 0.9rem;
  color: $light-black;
}
.btn-grey:hover,
.btn-grey:focus,
.btn-grey:active {
  background-color: $hover-grey !important;
  border-color: $hover-grey !important;
  color: $light-black !important;
}

//btn black
.btn-black {
  background-color: $light-black;
  min-width: 9rem;
  border-color: $light-black;
  font-size: 0.9rem;
  color: $white;
}
.btn-black:hover,
.btn-black:focus,
.btn-black:active {
  background-color: $black !important;
  border-color: $black !important;
  color: $white !important;
}

//react select
.select2,
.select-product {
  * {
    font-size: 0.95rem !important;
  }
  .css-13cymwt-control {
    min-width: 130px;
  }
  .css-t3ipsp-control {
    border-color: #d1d3e2;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
  }
  .css-t3ipsp-control:hover {
    border-color: #d1d3e2;
  }
}
.select2 > div,
.select2 > div:hover,
.select-product .css-13cymwt-control:hover {
  border: 1px solid #d1d3e2;
}
.select2.border-right-none {
  .css-13cymwt-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    min-width: 150px;
  }
  .css-t3ipsp-control {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
    z-index: 1;
    min-width: 150px;
  }
}

//all form
.form {
  input {
    font-size: 0.95rem;
  }
  .text-muted {
    font-size: 0.9rem;
  }
  .red {
    color: $red;
  }
}
@media (max-width: 950px) {
  .form.px-5 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .form {
    font-size: 0.9rem;
  }
}

//datatable
.datatable {
  nav * {
    font-size: 0.9rem;
    color: $light-black;
  }
  .rdt_Table {
    .rdt_TableHead {
      text-transform: uppercase;
      font-weight: bold;
      color: $white;
    }
    .rdt_TableHeadRow {
      background-color: $dark-blue;
      padding: 0.5rem 0;
      * {
        vertical-align: middle !important;
        white-space: normal !important;
      }
    }
    .rdt_TableCell,
    .rdt_TableHead {
      font-size: 0.9rem;
    }
    .rdt_TableCell * {
      white-space: normal !important;
    }
    .rdt_TableCell {
      text-transform: capitalize;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
    [data-column-id="email"].rdt_TableCell,
    [data-column-id="username"].rdt_TableCell {
      text-transform: lowercase;
    }
    [data-column-id="capitalizeUnset"].rdt_TableCell {
      text-transform: unset;
    }
    [data-column-id="capitalizeUnset2"].rdt_TableCell {
      text-transform: unset;
    }
    .rdt_TableRow.bEsVrs:nth-of-type(n) {
      background-color: $grey;
    }
    .rdt_TableRow.bEsVrs:nth-of-type(n):not(:last-of-type) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .rdt_TableRow:hover {
      background-color: $light-grey;
      outline: none;
      cursor: pointer;
    }
    .rdt_TableRow:not(:last-of-type):hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .btn-aksi {
      font-size: 0.9rem;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      &.btn-delete {
        background-color: $red;
        color: $white;
        border-color: $red;
      }
      &.btn-edit2 {
        background-color: $light-blue;
        color: $white;
        border-color: $light-blue;
      }
      &.btn-edit {
        background-color: $dark-blue;
        color: $white;
        border-color: $dark-blue;
      }
      &.btn-reset {
        background-color: $green;
        color: $white;
        border-color: $green;
      }
      &.btn-reject {
        background-color: $red;
        color: $white;
        border-color: $red;
      }
    }
  }
}

@media (max-width: 1290px) {
  .datatable {
    [data-column-id="aksi"].rdt_TableCell {
      display: block;
      text-align: center;
      .btn,
      a {
        margin: 0.1rem 0;
      }
      a .mr-1 {
        margin-right: 0 !important;
      }
    }
  }
}

//search
.input-search {
  font-size: 0.9rem;
  color: $light-black;
}

@media (max-width: 815px) {
  .filter-target .input-search {
    margin-top: 0.5rem;
  }
}

@media (max-width: 767px) {
  .filter-search .input-search,
  .filter-target .input-search {
    margin-top: 0.5rem;
  }
}

@media (max-width: 576px) {
  .input-search {
    margin-top: 0.5rem;
  }
}

//phone input
.PhoneInput {
  .PhoneInputInput {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #6e707e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d1d3e2;
    border-radius: 0.35rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .PhoneInputInput:focus {
    color: #6e707e;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
  }
  .PhoneInputInput:disabled {
    background-color: $light-grey;
  }
}

//alert
.swal2-container {
  .swal2-title {
    font-size: 1.5rem;
  }
  .swal2-html-container,
  .swal2-select {
    font-size: 1rem;
  }
  button.swal2-confirm {
    background-color: $dark-blue;
    font-size: 1rem !important;
  }
  button.swal2-confirm:active,
  button.swal2-confirm:hover {
    background-color: $hover-blue;
  }
  button.swal2-cancel {
    background-color: $grey !important;
    font-size: 1rem !important;
    color: $light-black !important;
  }
  button.swal2-cancel:active,
  button.swal2-cancel:hover {
    background-color: $hover-red;
  }
  .swal2-select:focus-visible {
    outline: unset;
  }
  .swal2-select {
    margin-top: 1.5rem;
  }
}

//input file
.input-file-custom {
  font-size: 0.9rem;
  padding-top: 0.19rem;
}

.monospace {
  white-space: nowrap;
  font-family: monospace;
}

.half-star {
  display: inline-block;
  width: 12px; /* Adjust the width to match the size of your stars */
  height: 24px; /* Adjust the height to match the size of your stars */
  position: relative;
  overflow: hidden;
}

.half-star:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, gold 50%, transparent 50%);
}

.table-overflow {
  overflow: visible;
}

.review {
  .image-zoom {
    display: flex;
    flex-wrap: wrap;
  }

  .image-zoom-item {
    position: relative;
    cursor: zoom-in;
    margin: 5px;
    transition: transform 0.3s;
    z-index: 1;
  }

  .image-zoom-item img {
    height: 120px;
  }

  .image-zoom-item.zoomed {
    transform: scale(1.5);
    cursor: zoom-out;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  .text-review {
    text-align: justify;
  }
}

.btn-green {
  background-color: $green;
  min-width: 9rem;
  border-color: $green;
  font-size: 0.9rem;
}
.btn-green:hover,
.btn-green:focus,
.btn-green:active {
  background-color: $hover-green !important;
  border-color: $hover-green !important;
}

.btn-red {
  background-color: $red;
  min-width: 9rem;
  border-color: $red;
  font-size: 0.9rem;
}
.btn-red:hover,
.btn-red:focus,
.btn-red:active {
  background-color: $hover-red !important;
  border-color: $hover-red !important;
}

.text-with-line {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.text-with-line::after {
  content: "";
  flex-grow: 1;
  height: 1px;
  background-color: $light-black; /* Adjust the color as needed */
  margin-right: 10px; /* Adjust the spacing between text and line */
  margin-left: 20px;
}

.form-detail {
  .form-control:disabled,
  .select2:disabled,
  .select2 > div {
    background-color: $light-grey !important;
  }
  .select2 * {
    color: #6e707e;
  }
  .select2.enabled > div {
    background-color: #fff !important;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: $light-grey !important;
  opacity: 1;
}

.react-datepicker__triangle {
  transform: translate(210px, 0px) !important;
}

.input-date-custom {
  font-size: 0.9rem !important;
  height: 38px;
  padding: auto;
}

.custom-table {
  font-size: 0.9rem;
  color: $light-black;
  thead th {
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    background-color: $dark-blue;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  tbody tr:hover {
    background-color: $light-grey;
    color: $light-black;
    cursor: pointer;
  }
  tbody tr.selected {
    background-color: $grey;
    color: $light-black;
  }
  .th-checkbox {
    width: 80px;
  }
}

.pagination.pagination-custom {
  font-size: 0.9rem;
  .page-link {
    color: $light-black;
  }
  .page-link.active {
    background-color: $dark-blue;
    color: $white;
  }
  .page-link:disabled {
    color: #ccc;
    background-color: $light-grey;
  }
}

.form-edit .form-control:disabled {
  background-color: $light-grey !important;
}

.disabled-select .css-16xfy0z-control {
  background-color: $light-grey !important;
}

.disabled-select .css-olqui2-singleValue,
.css-1dimb5e-singleValue {
  color: #6e707e !important;
}

input[type="checkbox" i]:disabled {
  background-color: $light-black;
}

.react-select__placeholder {
  width: 300px;
  overflow: hidden; /* Hide any overflow */
  white-space: nowrap;
}
.filter-search,
.filter-target {
  .form-control {
    height: 38px;
  }
}

.expanded-rows {
  padding: 6px 76px;
  font-size: 0.9rem;
  color: $light-black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background-color: $light-grey;
  .title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .body {
    table th {
      background-color: $grey;
    }
  }
}

.rdt_ExpanderRow:last-of-type .expanded-rows {
  border-bottom: none;
}

.td-vertical-align-middle tr td {
  vertical-align: middle;
}

.custom-table input[type="checkbox"] {
  cursor: pointer;
  // width: 15px; /* Set the desired width, adjust as needed */
  // height: 15px; /* Set the desired height, adjust as needed */
}

.th-vertical-align-middle th {
  vertical-align: middle !important;
}

.row-item.disabled {
  pointer-events: none; /* Disable pointer events to prevent interactions */
}

@media (max-width: 1021px) {
  .button-export {
    margin-bottom: 0.5rem;
  }
  .col-customer-export .button-export {
    margin-bottom: 0;
  }
}

@media (max-width: 850px) {
  .col-btn-export {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: 615px) {
  .col-customer-export .button-export {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 576px) {
  .button-export {
    margin-bottom: 0.5rem;
  }
}
.navbar-select__placeholder,
.overflow-select__placeholder {
  overflow: hidden;
  white-space: nowrap; /* Hide any overflow */
}

.div-backup-time-setup {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 815px) {
  .div-backup-time-setup {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.ultraCentered {
  padding: 100px 0;
  text-align: center;
}

.accordion .rdt_TableHeadRow {
  background-color: #1c5ddc;
  color: white;
  font-size: 14px;
  padding: 0.5rem;
  align-items: center;
  margin: 0 auto;
}

.sc-crozmw {
  margin: 0 auto;
}

.ghLWkf div:first-child {
  margin: 0 auto;
}
