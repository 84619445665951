$black: #000;
$light-black: #1c1b20;
$grey: #f0f0f0;
$light-grey: #f9f9f9;
$dark-blue: #1c5ddc;
$light-blue: #00aaff;
$white: #fff;
$yellow: #feb52b;
$green: #19ae5d;
$red: #fc351c;
$pink: #eb30a2;

//employee form
@media (max-width: 815px) {
  .form-users {
    .col-sm-12 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

//employee form
@media (max-width: 515px) {
  .form .btn-blue,
  .form .btn-grey {
    width: 100%;
    margin-right: 0;
  }
  .form .btn-grey {
    margin-bottom: 0.5rem;
  }
}

//tab
.nav-tabs.tab-custom {
  button.nav-link {
    font-size: 0.9rem;
    color: $light-black;
  }
}

//input file
.input-file-custom {
  font-size: 0.9rem;
  padding-top: 0.19rem;
}

#react-select-2-listbox,
#react-select-3-listbox,
#react-select-4-listbox {
  z-index: 2;
}
